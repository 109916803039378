import { clsx } from "clsx"
import * as React from "react"

const Loading = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 18"
    className={clsx("animate-spin", className)}
    {...props}
  >
    <path d="m15.6 17-3.8-7h7.7V8h-7.8l4-7-1.8-1L10 6.9 6.1 0 4.4 1l3.8 7H.5v2h7.8l-4 7 1.8 1 3.9-6.9 3.9 6.9 1.7-1Z" />
  </svg>
)

export default Loading
